exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-cereals-index-jsx": () => import("./../../../src/pages/cereals/index.jsx" /* webpackChunkName: "component---src-pages-cereals-index-jsx" */),
  "component---src-pages-megami-index-jsx": () => import("./../../../src/pages/megami/index.jsx" /* webpackChunkName: "component---src-pages-megami-index-jsx" */),
  "component---src-pages-producer-index-jsx": () => import("./../../../src/pages/producer/index.jsx" /* webpackChunkName: "component---src-pages-producer-index-jsx" */),
  "component---src-pages-recruit-index-jsx": () => import("./../../../src/pages/recruit/index.jsx" /* webpackChunkName: "component---src-pages-recruit-index-jsx" */),
  "component---src-pages-restaurant-index-jsx": () => import("./../../../src/pages/restaurant/index.jsx" /* webpackChunkName: "component---src-pages-restaurant-index-jsx" */),
  "component---src-pages-riceflour-2-index-jsx": () => import("./../../../src/pages/riceflour2/index.jsx" /* webpackChunkName: "component---src-pages-riceflour-2-index-jsx" */),
  "component---src-pages-riceflour-index-jsx": () => import("./../../../src/pages/riceflour/index.jsx" /* webpackChunkName: "component---src-pages-riceflour-index-jsx" */),
  "component---src-pages-ryunohitomi-index-jsx": () => import("./../../../src/pages/ryunohitomi/index.jsx" /* webpackChunkName: "component---src-pages-ryunohitomi-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

